import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import { makeStyles, withStyles } from "@material-ui/core/styles"
import {
  Box,
  Typography,
  Container,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from "@material-ui/core"
import Gestion from "../assets/Footer/gestion.png"
import Apertura2 from "../assets/Footer/apertura2.png"
import Apertura from "../assets/Footer/apertura.png"
import TazaNominal from "../assets/Footer/tasa-nominal.png"
import TazaNominal2 from "../assets/Footer/tasa-nominal-sm.png"


const useStyles = makeStyles(theme => ({
  containerPart: {
    [theme.breakpoints.down("md")]: {
      marginTop: "6em"
    },
    marginTop: "10em"
  },
  table: {
    minWidth: 700
  },
  media: {
    width: "100%"
  },
  colorGreen: {
    color: "#00e676",
    fontWeight: 900
  }
}))

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  body: {
    fontSize: 14
  }
}))(TableCell)

const StyledTableRow = withStyles(theme => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover
    }
  }
}))(TableRow)

function createData(service, cost) {
  return { service, cost }
}

const rows = [
  createData("Creación de cuenta y acceso a la plataforma CFL", "Gratis"),
  createData("Consulta e inversión en productos de CFL", "Gratis"),
  createData("Asesoría por apoyo en inversión en CFL", "Gratis"),
  createData(
    "Coneccion con la cuenta de banco del cliente para hacer transferencias bancarias directas a la cuenta de CFL",
    "Gratis"
  ),
  createData("Análisis y asesoría de manejo de cuenta", "Gratis"),
  createData("Servicio del Mercado Secundario", "Gratis"),
  createData(
    "Comisión por Administración",
    "Variable en función de la rentabilidad esperada del producto y la tasa de interés pagada por el Solicitante, con periodicidad mensual, véase la fórmula"
  ),
  createData("Comisión de retiro producto Acceso", "Gratis"),
  createData(
    "Comisión de retiro producto Uno y mercado",
    "Puntual, Véase Fórmula"
  )
]
const rowsSol = [
  createData("Solicitud de créditos", "Gratis"),
  createData("Análisis de la aplicación y de la documentación", "Gratis"),
  /*createData(
    "Comisión de Gestión",
    "Variable entre el 0.1% y el 1% del monto solicitado, en función de la calificación crediticia y aplicable para cada uno de los plazos del financiamiento. "
  ),*/
  createData("Elaboración del Contrato", "Gratis"),
  createData("Formalización y Firma del Contrato", "Gratis"),
  createData(
    "Comisión de Apertura",
    "Variable entre el 3.5% y el 30% del monto solicitado, dependiendo de la calificación crediticia y del plazo del financiamiento"
  ),
  createData("Comisión por pago adelantado", "Gratis"),
  createData(
    "Intereses moratorios",
    "Por pagos fuera de término. Es el 100% adicional sobre TNA y se calcula por día de atraso (una vez transcurridos 5 días de la fecha establecida)"
  ),
  createData(
    "Gastos de Cobranza",
    "Recupero del costo por las notificaciones legales por las faltas de pago"
  ),
  createData("Comisión por pago retrasado", "Gratis")
]

export default function Index() {
  const classes = useStyles()
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          CFL | Consulte los costes y comisiones de nuestros productos
        </title>
      </Helmet>
      <Container className={classes.containerPart}>
        <Box maxWidth="80%" m="auto">
          <Box mt={4} py={2}>
            <Typography variant="h1" component="h1" gutterBottom>
              <b>Consulte los costes y comisiones de nuestros productos</b>
            </Typography>
          </Box>
          <Typography variant="h2" gutterBottom inline>
            Comisiones Inversionistas
          </Typography>
          <Box mt={4} py={2}>
            <Grid container spacing={2} justify="center" alignItems="center">
              <Grid item xs={12}>
                <TableContainer component={Paper}>
                  <Table
                    className={classes.table}
                    aria-label="customized table"
                  >
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>Servicio a otorgar</StyledTableCell>
                        <StyledTableCell align="right">
                          Costo al Cliente Inversionista
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map(row => (
                        <StyledTableRow key={row.name}>
                          <StyledTableCell component="th" scope="row">
                            {row.service}
                          </StyledTableCell>
                          {row.cost === "Gratis" ? (
                            <StyledTableCell
                              align="center"
                              className={classes.colorGreen}
                            >
                              {row.cost}
                            </StyledTableCell>
                          ) : (
                            <StyledTableCell align="center">
                              {row.cost}
                            </StyledTableCell>
                          )}
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
            <Typography variant="body1" gutterBottom inline>
              Comisión por Administración =
              (TNAcreditado1%Asignado1+TNAcreditado2%Asignado2+...+TNAcreditadon%Asignadon)
              - RE
            </Typography>
            <Typography variant="body1" gutterBottom inline>
              TN = Tasa Nominal Mensual{" "}
            </Typography>
            <Typography variant="body1" gutterBottom inline>
              RE = Rentabilidad Esperada del producto seleccionado por el
              Inversionista en términos mensuales
            </Typography>
            <Typography variant="body1" gutterBottom inline>
              Comisión de retiro = max(0;cantidad retiro * minimo(1-(deposito /
              saldo de cuenta),(fecha finalización - fecha
              retiro)((2.5%+1)1/365-1))
            </Typography>
            <Box mt={4} py={2}>
              <Typography variant="h3" gutterBottom inline>
              Tasa Nominal Anual
              </Typography>
              <Grid container spacing={2} justify="center" alignItems="center">
                <Grid item xs={6}>
                  <img
                    className={classes.media}
                    src={TazaNominal}
                    alt="Tasa Nominal Anual"
                  />
                </Grid>
              </Grid>
            </Box>
            <Box mt={4} py={2}>
              <Typography variant="h3" gutterBottom inline>
              Tasa Nominal Anual
              </Typography>
              <Grid container spacing={2} justify="center" alignItems="center">
                <Grid item xs={6}>
                  <img
                    className={classes.media}
                    src={TazaNominal2}
                    alt="Tasa Nominal Anual"
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Typography variant="h2" gutterBottom inline>
            Comisiones Solicitantes
          </Typography>
          <Box mt={4} py={2}>
            <Grid container spacing={2} justify="center" alignItems="center">
              <Grid item xs={12}>
                <TableContainer component={Paper}>
                  <Table
                    className={classes.table}
                    aria-label="customized table"
                  >
                    <TableHead>
                      <TableRow>
                        <StyledTableCell align="center">
                          Servicio a otorgar
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          Costo al Cliente Inversionista
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rowsSol.map(row => (
                        <StyledTableRow key={row.name}>
                          <StyledTableCell component="th" scope="row">
                            {row.service}
                          </StyledTableCell>
                          {row.cost === "Gratis" ? (
                            <StyledTableCell
                              align="center"
                              className={classes.colorGreen}
                            >
                              {row.cost}
                            </StyledTableCell>
                          ) : (
                            <StyledTableCell align="center">
                              {row.cost}
                            </StyledTableCell>
                          )}
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
            {/* <Box mt={4} py={2}>
              <Typography variant="h3" gutterBottom inline>
                Comisión de Gestión
              </Typography>
              <Grid container spacing={2} justify="center" alignItems="center">
                <Grid item xs={6}>
                  <img
                    className={classes.media}
                    src={Gestion}
                    alt="comisión de gestión"
                  />
                </Grid>
              </Grid>
            </Box> */}
            <Box mt={4} py={2}>
              <Typography variant="h3" gutterBottom inline>
                Comisión de Apertura
              </Typography>
              <Grid container spacing={2}>
                <Grid
                  container
                  item
                  xs={12}
                  lg={6}
                  justify="center"
                  alignItems="center"
                >
                  <img
                    className={classes.media}
                    src={Apertura}
                    alt="Comisión Apertura"
                  />
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  lg={6}
                  justify="center"
                  alignItems="center"
                >
                  <img
                    className={classes.media}
                    src={Apertura2}
                    alt="comisión Apertura"
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Container>
    </Layout>
  )
}
